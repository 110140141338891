import { Button } from '@/components/common/Button';
import { LiffContext } from '@/components/liff-provider';
import { useStore } from '@/hooks/useStore';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import liff from '@line/liff';
import { toastify } from '@/utils/toastify';
import { flowResult } from 'mobx';
import { ROUTES } from '@/configs/constants';
import { FileText, Loader } from 'lucide-react';
import { useLocation } from 'react-router-dom';
import iconQr from '../../assets/images/qr.png';
import { getStorage, saveLocalStorage } from '@/utils/browsers';
import { keyStorage } from '@/constant/string';
import { Customer } from '@/types/auth';
import { ThreeCircles } from 'react-loader-spinner';
import axios from 'axios';
import { AuthApi } from '@/apis';
import logo from '../../assets/images/logo_red.png';
import packageJson from '../../../package.json';

function useQuery(): URLSearchParams {
    return new URLSearchParams(useLocation().search);
}

export default observer(function LoginPage() {
    const [currentInvitationId, setCurrentInvitationId] = useState<String>('');
    const [checking, setChecking] = useState<boolean>(true);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { liffState } = useContext(LiffContext);
    const query = useQuery();
    const invitationId = query.get('invitation');
    const {
        authStore: { signinQrCode, setLiffID },
        apiStore: { setLoading },
        modalStore: { showErrorModal }
    } = useStore();

    let loging = false;
    useEffect(() => {
        checkAuth();
        loging = true;
    }, []);

    useEffect(() => {
        if (invitationId && invitationId !== currentInvitationId) {
            setCurrentInvitationId(invitationId);
            handleInvitation(invitationId);
        }
    }, [invitationId]);

    useEffect(() => {
        window.addEventListener('popstate', () => {
            try {
                liff.closeWindow();
            } catch (error) {

            }
        });
        const urlParams = new URLSearchParams(window.location.search);
        const liffStateEncoded = urlParams.get('liff.state');
        if (liffStateEncoded) {
            const liffStateDecodedOnce = decodeURIComponent(liffStateEncoded);
            const liffStateDecodedTwice = decodeURIComponent(liffStateDecodedOnce);
            const stateParams = new URLSearchParams(liffStateDecodedTwice);
            const liff_id = stateParams.get(keyStorage.LIFF_ID);
            const oldLiffId = getStorage(keyStorage.LIFF_ID);
            if (liff_id && liff_id !== oldLiffId) {
                setLiffID(liff_id);
                saveLocalStorage(keyStorage.LIFF_ID, liff_id);
            }
        }
    }, []);

    const checkAuth = async () => {
        if (loging) {
            return;
        }
        const token = getStorage(keyStorage.TOKEN);
        const profile: Customer = getStorage(keyStorage.PROFILE_USER, true);
        const uuid = getStorage(keyStorage.UUID);
        if (token && profile && uuid) {
            loging = false;
            setTimeout(() => {
                const urlParams = new URLSearchParams(window.location.search);
                const liffState = urlParams.get('liff.state');
                if (liffState) {
                    const decodedState = decodeURIComponent(liffState);
                    const stateParams = new URLSearchParams(decodedState);
                    const invoiceId = stateParams.get('invoiceId');
                    if (invoiceId) {
                        navigate(ROUTES.viewInvoice.href, { state: { id: invoiceId, fromMessage: true } });
                    } else {
                        navigate(ROUTES.home.href, { state: { isShowModal: false, checkIdLine: true } });
                    }
                } else navigate(ROUTES.home.href, { state: { isShowModal: false, checkIdLine: true } });
                setChecking(false);
            }, 240);
        } else setChecking(false);
    };

    const handleInvitation = (invitationId: String) => {
        console.log('invitationId: ', invitationId);
    };
    const getUserUUID = (url) => {
        if (!url) {
            return false;
        }
        const urlParams = new URLSearchParams(new URL(url).search);
        return urlParams.get('user_uuid');
    };

    const handleLogin = async () => {
        // const payload = { uuid: '0fd72987-3ff8-4a82-8bc2-7eca3f805bbe', line_id: 'Uc0d4b232d7879870f1ceb48917348ac4z' };
        // const resLogin = await flowResult(signinQrCode(payload));
        // if (resLogin) {
        //     setTimeout(() => {
        //         navigate(ROUTES.home.href, { state: { isShowModal: false, checkIdLine: false } });
        //     }, 240);
        // } else {
        //     setTimeout(() => {
        //         showErrorModal({
        //             content: t('messages.sign_in_failed')
        //         });
        //     }, 240);
        // }
        setLoading(true);
        let liff_id = getStorage(keyStorage.LIFF_ID);
        if (!liff || !liff_id) {
            setLoading(false);
            showErrorModal({
                content: t('messages.liff_error')
            });
            return;
        }
        if (!liff.isInClient()) {
            setLoading(false);
            showErrorModal({
                content: t('messages.not_in_Line')
            });
            return;
        }
        const accessToken = liff.getIDToken();
        if (!accessToken) {
            setLoading(false);
            showErrorModal({
                content: t('messages.liff_error')
            });
            return;
        }
        const lineProfile: any = await new AuthApi().getProfileLine(accessToken);
        if (liff.isApiAvailable('scanCodeV2') || !lineProfile) {
            setTimeout(async () => {
                setLoading(false);
                const result = await liff.scanCodeV2();
                if (!result) {
                    return;
                }
                if (!result.value) {
                    showErrorModal({
                        content: t('messages.QR_invalid')
                    });
                    return;
                }
                const uuid = getUserUUID(result.value);
                if (!uuid) {
                    showErrorModal({
                        content: t('messages.QR_invalid')
                    });
                    return;
                }
                const line_id = lineProfile?.sub || undefined;
                const payload = { uuid: uuid, line_id: line_id };
                const resLogin = await flowResult(signinQrCode(payload));
                if (resLogin) {
                    saveLocalStorage(keyStorage.UUID, uuid);
                    setTimeout(() => {
                        if (!line_id) {
                            showErrorModal({
                                id: 'get_Line_info_error',
                                content: t('messages.get_Line_info_error')
                            });
                        }
                        navigate(ROUTES.home.href, { state: { isShowModal: true, checkIdLine: false } });
                    }, 240);
                } else {
                    setTimeout(() => {
                        showErrorModal({
                            content: t('messages.sign_in_failed')
                        });
                    }, 240);
                }
            }, 2000);
        } else {
            setTimeout(() => {
                handleLogin();
            }, 1000);
        }
    };

    return (
        <div className='w-full h-full '>
            {checking ?
                (
                    <div className='fixed w-screen h-screen top-0 left-0 z-[9999] bg-white flex items-center justify-center'>
                        <ThreeCircles
                            width={60}
                            height={60}
                            color=''
                        />
                    </div>
                ) :
                (
                    <div className='w-full  fixed top-[30%]'>
                        <div className='w-full flex justify-center'>
                            <img className='w-2/3' src={logo} />
                        </div>
                        <div className='w-full flex justify-center mt-14'>
                            <Button className='bg-main text-white w-[376px] h-[72px] rounded-xl flex' onClick={() => handleLogin()}>
                                <img src={iconQr} className='mr-[16px] w-[18px] h-[18px]' />
                                <p className='text-[22px] font-light'>QRコードでログイン</p>
                            </Button>
                        </div>
                    </div>
                )}
        </div>
    );
});
