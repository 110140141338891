import { useStore } from '@/hooks/useStore';
import { observer } from 'mobx-react-lite';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogPortal, DialogTitle } from '../ui/DialogUI';
import { DialogClose } from '@radix-ui/react-dialog';
import { X } from 'lucide-react';
import { cva } from 'class-variance-authority';
import { cn } from '@/utils/utils';
import ModalFooter from './ModalFooter';

export const modalVariants = cva(
    '',
    {
        variants: {
            type: {
                default: '',
                success: 'text-[#07bc0c]',
                error: 'text-[#e74c3c]',
                info: 'text-[#3498db]',
                warn: 'text-[#f1c40f]',
                form: 'text-black text-[24px] border-b-[1px] border-b-gray-500 pb-4'
            },
            size: {
                'default': 'max-w-lg',
                'alert': 'max-w-lg',
                'xs': 'max-w-xs',
                'sm': 'max-w-sm',
                'md': 'max-w-md',
                'lg': 'max-w-lg',
                'xl': 'max-w-xl',
                '2xl': 'max-w-2xl',
                '3xl': 'max-w-3xl',
                '4xl': 'max-w-4xl',
                '5xl': 'max-w-5xl',
                '6xl': 'max-w-6xl',
                '7xl': 'max-w-7xl'
            }
        },
        defaultVariants: {
            type: 'default',
            size: 'default'
        }
    }
);

export default observer(function Modals() {
    // store
    const { modalStore: { instances, hideModal } } = useStore();

    return (
        <>
            {
                instances.map((modal, index) => {
                    return (
                        <Dialog
                            key={modal.id ?? index}
                            open={true}
                            onOpenChange={() => { }}
                        >
                            <DialogPortal>
                                <DialogContent
                                    className={cn('max-w-[90vw]', modalVariants({ size: modal.size }))}
                                    onPointerDownOutside={() => {
                                        modal.closeWhenOutside && hideModal(modal.id);
                                    }}
                                >
                                    <DialogHeader className='rounded-t-sm'>
                                        <DialogTitle
                                            className={cn(
                                                // modalVariants({ type: modal.type }),
                                                modal.titleClassName,
                                                'w-full text-[18px]'
                                            )}
                                        >
                                            {modal.title}
                                        </DialogTitle>
                                        {modal.description && (
                                            <DialogDescription>
                                                {modal.description}
                                            </DialogDescription>
                                        )}
                                        {modal.headerRender}
                                    </DialogHeader>
                                    {
                                        modal.showCloseIcon && (
                                            <DialogClose
                                                className='absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground text-white'
                                                onClick={() => hideModal(modal.id)}
                                            >
                                                <X className='h-6 w-6' />
                                                <span className='sr-only'>
                                                    Close
                                                </span>
                                            </DialogClose>
                                        )
                                    }
                                    <div className='px-8 pb-4 '>
                                        {modal.content}
                                    </div>
                                    <DialogFooter className={cn('flex items-center justify-end gap-4', modal.footerClassName)}>
                                        {
                                            modal.footer ?
                                                modal.footer :
                                                (
                                                    <ModalFooter
                                                        cancelButton={modal.cancelButton}
                                                        cancelButtonClassName={modal.cancelButtonClassName}
                                                        onCancel={modal.onCancel}
                                                        saveButton={modal.saveButton}
                                                        saveButtonClassName={modal.saveButtonClassName}
                                                        onSave={modal.onSave}
                                                    />
                                                )
                                        }
                                    </DialogFooter>
                                </DialogContent>
                            </DialogPortal>
                        </Dialog>
                    );
                })
            }
        </>
    );
});
