import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useNavigate
import { useStore } from '@/hooks/useStore';
import { flowResult } from 'mobx';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { ArrowLeft, ArrowRight, ChevronLeftIcon, DownloadIcon } from 'lucide-react';
import { toastify } from '@/utils/toastify';
import { ThreeCircles } from 'react-loader-spinner';
import liff from '@line/liff';
import { ROUTES } from '@/configs/constants';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

export default function ViewInvoicePage() {
    const location = useLocation();
    const navigate = useNavigate(); // Hook to navigate between pages
    const { id, nameFile, fromMessage, path } = location.state || {}; // Lấy ID từ route
    const [currentId, setCurrentId] = useState(undefined);
    const [pdfUrl, setPdfUrl] = useState<string | undefined>(undefined);
    const [width, setWidth] = useState(window.innerWidth);

    const [numPages, setNumPages] = useState<number>(1);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const {
        filesStore: { getDetailInvoice }
    } = useStore();

    const [startTouch, setStartTouch] = useState(0);

    // Lắng nghe thay đổi kích thước cửa sổ
    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Lấy file PDF khi có ID
    useEffect(() => {
        if (id && id !== currentId) {
            setCurrentId(id);
        }
    }, [id]);

    useEffect(() => {
        if (currentId) {
            getFile(currentId);
        }
    }, [currentId]);

    const getFile = async (id: number) => {
        if (id === undefined) return;
        try {
            const response = await flowResult(getDetailInvoice(id));
            if (response && response instanceof Blob) {
                const objectUrl = URL.createObjectURL(response);
                setPdfUrl(objectUrl);
            } else {
                console.error('Unexpected response:', response);
            }
        } catch (error) {
            console.error('Error fetching invoice:', error);
        }
    };

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);
    }

    const handleTouchStart = (e: React.TouchEvent) => {
        setStartTouch(e.touches[0].clientX); // point start swipe
    };

    const handleTouchMove = (e: React.TouchEvent) => {
        const touchEnd = e.touches[0].clientX; // point swipe end
        const diff = startTouch - touchEnd;

        if (diff > 100 && pageNumber < numPages) {
            // Swipe next
            setPageNumber(prev => prev + 1);
        } else if (diff < -100 && pageNumber > 1) {
            // Swipe back
            setPageNumber(prev => prev - 1);
        }
    };

    const handleDownload = () => {
        // console.log(pdfUrl); // Kiểm tra pdfUrl
        // if (pdfUrl) {
        //     try {
        //         window.open(pdfUrl, '_blank');
        //     } catch (error) {
        //         toastify('error', 'error');
        //     }
        // } else {
        //     toastify('error', 'No valid PDF URL');
        // }
        const url = `https://drive.google.com/uc?export=download&id=${path}`;
        try {
            liff.openWindow({
                url: url,
                external: true
            });
            // window.open(url, '_blank');
        } catch (error) {
            alert(error);
        }
    };

    return (
        <div className='relative pb-24 h-full content-center'>
            <div
                className='fixed top-0 left-0 w-full bg-main text-white text-center py-2 z-50 px-4 cursor-pointer justify-between'
            >
                <div className='w-full flex justify-betweent items-center'>
                    <ChevronLeftIcon
                        onClick={() => {
                            navigate(ROUTES.home.href, { state: { isShowModal: false, checkIdLine: false } });
                        }}
                        size={32}
                    />
                    <DownloadIcon
                        onClick={() => {
                            handleDownload();
                        }}
                        size={32}
                    />
                </div>
            </div>
            {pdfUrl ?
                (
                    <div
                        className='relative'
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                    >

                        <Document
                            loading=''
                            file={pdfUrl}
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            <Page
                                loading='ページを読み込んでいます。'
                                pageNumber={pageNumber}
                                width={Math.min(width - 40, 595)}
                            />
                        </Document>
                        <div className='fixed bottom-0 left-0 w-full bg-gray-400 text-white text-center py-2 z-50'>
                            <div className=' flex justify-around'>
                                <button
                                    className=' disabled:opacity-40'
                                    disabled={pageNumber === 1}
                                    onClick={() => {
                                        if (pageNumber > 1) {
                                            setPageNumber(prev => prev - 1);
                                        }
                                    }}
                                >
                                    <ArrowLeft />
                                </button>
                                <div>
                                    {pageNumber}
                                    ページ目/
                                    {numPages}
                                    ページ中
                                </div>
                                <button
                                    className=' disabled:opacity-40'
                                    disabled={numPages === pageNumber}
                                    onClick={() => {
                                        if (pageNumber < numPages) {
                                            setPageNumber(prev => prev + 1);
                                        }
                                    }}
                                >
                                    <ArrowRight />
                                </button>
                            </div>

                        </div>
                    </div>
                ) :
                (
                    <div className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                        <ThreeCircles
                            width={60}
                            height={60}
                            color=''
                        />
                    </div>

                )}
        </div>
    );
}
