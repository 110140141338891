import React, { createContext, useEffect, useState, ReactNode } from 'react';
import liff from '@line/liff';
import { getStorage, saveLocalStorage } from '@/utils/browsers';
import { keyStorage } from '@/constant/string';

interface LiffContextType {
    liffState: typeof liff | null,
    liffError: string | null
}

export const LiffContext = createContext<LiffContextType>({
    liffState: null,
    liffError: null
});

export const LiffProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [liffState, setLiffState] = useState<any>(undefined);
    const [liffError, setLiffError] = useState<any>(undefined);
    let liff_id = getStorage(keyStorage.LIFF_ID);

    const checkLiffId = () => {
        const liffId = getStorage(keyStorage.LIFF_ID);
        if (liffId) {
            if (liffId !== liff_id) {
                liff_id = liffId;
            }
        } else {
            setTimeout(() => {
                checkLiffId();
            }, 240);
        }
    };

    useEffect(() => {
        checkLiffId();
    }, []);

    useEffect(() => {
        if (liff_id && liff_id.length > 0) {
            try {
                liff
                    .init({ liffId: liff_id.toString() })
                    .then(async () => {
                        setLiffState(liff);
                    })
                    .catch((error: Error) => {
                        setLiffError(error.toString());
                    });
            } catch (error) {

            }
        }
    }, [liff_id]);

    return (
        <LiffContext.Provider value={{ liffState, liffError }}>
            {children}
        </LiffContext.Provider>
    );
};
