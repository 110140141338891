import ApiService from './ApiService';

export default class FileApi extends ApiService {
    getInvoices(payload) {
        const { upload_month, upload_year } = payload;
        return this.get('invoices', { upload_month: upload_month, upload_year: upload_year });
    }

    getInvoice(id) {
        return this.get(`invoice/${id}`, {}, { responseType: 'blob' });
    }

    getMyInfo() {
        return this.get('info');
    }
}
