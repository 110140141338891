import Breadcrumb from '@/components/common/Breadcrumb';
import Footer from '@/components/layout/Footer';
import Navbar from '@/components/layout/menu-bar/Navbar';
import Sidebar from '@/components/layout/menu-bar/Sidebar';
import { useStore } from '@/hooks/useStore';
import { getBreadcrumb, getTitlePage } from '@/utils/routes';
import { cn } from '@/utils/utils';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

export default observer(function AdminPanelLayout() {
    // hooks
    const { pathname } = useLocation();

    // variables
    const title = getTitlePage(pathname);
    const breadcrumb = getBreadcrumb(pathname);

    // store
    const {
        uiStore: { isOpenSidebar },
        authStore: { getProfile }
    } = useStore();

    // lifecycle
    // useEffect(() => {
    //     getProfile();
    // }, [pathname]);

    return (
        <>
            {/* <Sidebar /> */}
            <main className='w-screen h-screen bg-bgMain flex justify-center items-center'>
                {/* <Navbar title={title} /> */}
                {/* <div className='container pt-8 pb-8 px-4 sm:px-8'>
                    <Breadcrumb breadcrumb={breadcrumb} /> */}
                <Outlet />
                {/* </div> */}
            </main>
            {/* <footer
                className={cn(
                    'transition-[margin-left] ease-in-out duration-300',
                    isOpenSidebar === false ? 'lg:ml-[90px]' : 'lg:ml-72'
                )}
            >
                <Footer />
            </footer> */}
        </>
    );
});
